import AWS from 'aws-sdk';

const payload = {
  S3_BUCKET: process.env.VUE_APP_S3_BUCKET,
  S3_HP: process.env.VUE_APP_S3_HP,
  REGION: process.env.VUE_APP_REGION,
  IDENTITY_POOL_ID: process.env.VUE_APP_IDENTITY_POOL_ID
};

AWS.config.update({
  region: payload.REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: payload.IDENTITY_POOL_ID
  })
});

const s3 = new AWS.S3({
  apiVersion: '2006-03-01'
});

async function uploadFileToS3({ key, contentType, file }) {
  try {
    const res = await s3
      .upload({
        Bucket: payload.S3_BUCKET,
        ACL: 'public-read',
        Key: key,
        Body: file,
        ContentType: contentType,
        ContentDisposition: 'inline',
        Metadata: { 'access-control-allow-origin': '*' }
      })
      .promise();
    return `${process.env.VUE_APP_STORAGE_DOMAIN}/${res.Key}`;
  } catch (error) {
    console.log(error);
  }
}
export { uploadFileToS3 };
